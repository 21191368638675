import axios from "axios";
import { defineStore } from "pinia";
import { useQuasar } from "quasar";
import { computed, reactive, ref } from "vue";
import useHelpers from "composables/useHelpers";
import { shouldFetch } from "common/fetchHelpers.js";
import { cloneDeep } from "lodash";

/**
 * Settings Store
 *
 * This store manages the state and actions related to settings.
 */
export const useSettingsStore = defineStore("settings", () => {
  // Quasar
  const $q = useQuasar();

  const { schoolId } = useHelpers();

  const initialSchoolSettings = reactive({});
  const initialRestrictedSchoolSettings = reactive({});
  const schoolSettings = reactive({});
  const restrictedSchoolSettings = reactive({});
  const initialUserSettings = reactive({});
  const userSettings = reactive({});
  const schools_without_new_processing_agreement = ref([]);
  const educationLevels = ref([]);
  const portfolioTags = ref([]);
  const initialPortfolioTags = ref([]);
  const portfolioSkills = ref([]);
  const initialPortfolioSkills = ref([]);
  const portfolioCategories = ref([]);
  const initialPortfolioCategories = ref([]);
  const ratingTypes = ref([]);
  const initialRatingTypes = ref([]);

  const fetchingData = ref(false);
  const updatingData = ref(false);

  const hasFetched = ref({
    ratingTypes: false,
  });

  /**
   * Email form list.
   * @type {import('vue').Ref<Array<Object>>}
   */
  const emailFormList = ref([
    {
      key: "email_sync",
      label: "Synchronisatie fouten",
    },
    {
      key: "email_help",
      label: "Help formulieren",
    },
    {
      key: "email_license",
      label: "Licentie berichten",
    },
  ]);

  // Computed properties
  /**
   * Returns boolean when use categories for skills is enabled.
   * @type {import('vue').ComputedRef<boolean>}
   */
  const usePortfolioCategories = computed(() => {
    return initialSchoolSettings.portfolio_settings.settings
      .allow_skill_categories;
  });

  /**
   * Check if grades are enabled for the school.
   * @type {import('vue').ComputedRef<boolean>}
   */
  const gradesEnabled = computed(() => {
    return !!(
      initialSchoolSettings.student_meeting_settings.settings.enable_se_grade ||
      initialSchoolSettings.student_meeting_settings.settings
        .enable_rapport_grade
    );
  });

  /**
   * Returns a string of the allowed file types for the portfolio.
   * @type {import('vue').ComputedRef<string>}
   */
  const allowedFileTypes = computed(() => {
    const { disallowed_file_types } =
      initialSchoolSettings.portfolio_settings.settings;

    const allowedTypes = disallowed_file_types.filter((type) => type.allowed);

    return allowedTypes.map((type) => type.label).join(", ");
  });

  /**
   * Show banners in the settings.
   * @type {import('vue').ComputedRef<Array<Object>>}
   */
  const portfolioBanners = computed(() => {
    return [
      {
        type: "warning",
        message: `Categorieën staan ingeschakeld maar er zijn nog vaardigheden zonder categorie. Koppel hier een categorie aan onder "vaardigheden beheren".`,
        show:
          usePortfolioCategories.value &&
          portfolioSkills.value.some((skill) => skill.category_id === null),
      },
    ];
  });

  // Methods
  /**
   * Formats the school email data.
   * @param {Object} email_settings - The email settings.
   * @returns {Array<Object>} The formatted email data.
   */
  const formatSchoolEmailData = (email_settings) => {
    const emails = [];

    const emailsByType = emailFormList.value
      .flatMap(({ key }) => key)
      .map((type) => ({
        type,
        emails: !email_settings[type]
          ? []
          : [
              ...new Set(
                email_settings[type].split(",").map((email) => email.trim()),
              ),
            ],
      }));

    emailsByType.forEach(({ type, emails: _emails }) => {
      _emails.forEach((email) => {
        const found = emails.find(({ email: _email }) => _email === email);

        if (found) {
          return !found.enabledCategories.includes(type)
            ? found.enabledCategories.push(type)
            : null;
        }

        return emails.push({
          email,
          enabledCategories: [type],
        });
      });
    });

    return emails;
  };

  // Actions
  /**
   * Fetch the school settings by school id.
   * @param {number|null} id - The school ID.
   * @param {boolean} forceRefetch - Whether to force refetch the data.
   * @returns {Promise<Object>} The school settings.
   */
  const getSchoolSettings = async (id = null, forceRefetch = false) => {
    id = id || useHelpers().schoolId;

    if (Object.keys(schoolSettings).length !== 0 && !forceRefetch) {
      return;
    }

    try {
      fetchingData.value = true;

      const {
        data: { data: schoolData },
      } = await axios.get(`/api/school/${id}/settings`);

      schoolData.formatted_email_settings = formatSchoolEmailData(
        schoolData.email_settings,
      );

      Object.assign(schoolSettings, schoolData);

      // Store the initial school settings before updating without Object reference
      Object.assign(
        initialSchoolSettings,
        JSON.parse(JSON.stringify(schoolData)),
      );

      return schoolData;
    } catch (error) {
      if (axios.isAxiosError(error)) {
        console.log(error);

        return Promise.reject(error.response);
      }
    } finally {
      fetchingData.value = false;
    }
  };

  /**
   * Fetch the restricted school settings by school id.
   * @param {number} id - The school ID.
   * @param {boolean} forceRefetch - Whether to force refetch the data.
   * @returns {Promise<Object>} The restricted school settings.
   */
  const getRestrictedSchoolSettings = async (id, forceRefetch = false) => {
    id = id || useHelpers().schoolId;

    if (Object.keys(restrictedSchoolSettings).length !== 0 && !forceRefetch) {
      return;
    }

    try {
      fetchingData.value = true;

      const {
        data: { data: schoolData },
      } = await axios.get(`/api/schools/${id}`);

      Object.assign(restrictedSchoolSettings, schoolData);

      // Store the initial school settings before updating without Object reference
      Object.assign(
        initialRestrictedSchoolSettings,
        JSON.parse(JSON.stringify(schoolData)),
      );

      return schoolData;
    } catch (error) {
      if (axios.isAxiosError(error)) {
        console.log(error);

        return Promise.reject(error.response);
      }
    } finally {
      fetchingData.value = false;
    }
  };

  /**
   * Get the current authenticated user settings.
   * @returns {Promise<Object>} The user settings.
   */
  const getUserSettings = async () => {
    if (Object.keys(userSettings).length !== 0) {
      return;
    }

    try {
      fetchingData.value = true;

      const { data } = await axios.get(`/api/settings`);

      Object.assign(userSettings, data.data);
      Object.assign(initialUserSettings, JSON.parse(JSON.stringify(data.data)));

      return userSettings;
    } catch (error) {
      if (axios.isAxiosError(error)) {
        console.log(error);
        return Promise.reject(error.response);
      }
    } finally {
      fetchingData.value = false;
    }
  };

  /**
   * Get the education levels.
   * @returns {Promise<Array<Object>>} The education levels.
   */
  const getEducationLevels = async () => {
    if (Object.keys(educationLevels.value).length !== 0) {
      return;
    }

    try {
      fetchingData.value = true;

      const { data } = await axios.get(
        `/api/school/${schoolId}/education-levels`,
      );

      Object.assign(educationLevels.value, data);

      return educationLevels.value;
    } catch (error) {
      if (axios.isAxiosError(error)) {
        console.log(error);
        return Promise.reject(error.response);
      }
    } finally {
      fetchingData.value = false;
    }
  };

  /**
   * Get the portfolio tags.
   * @param {Object} options - The options for fetching portfolio tags.
   * @param {boolean} [options.forceRefetch=false] - Whether to force refetch the data.
   * @returns {Promise<Array<Object>>} The portfolio tags.
   */
  const getPortfolioTags = async (options = {}) => {
    const { forceRefetch = false } = options;

    if (portfolioTags.value.length !== 0 && !forceRefetch) {
      return;
    }

    try {
      fetchingData.value = true;

      const { data } = await axios.get(
        `/api/school/${schoolId}/portfolio-tags`,
      );

      portfolioTags.value = data.data;
      initialPortfolioTags.value = JSON.parse(JSON.stringify(data.data));

      return portfolioTags;
    } catch (error) {
      if (axios.isAxiosError(error)) {
        console.log(error);
        return Promise.reject(error.response);
      }
    } finally {
      fetchingData.value = false;
    }
  };

  /**
   * Fetch the rating types.
   * @param {boolean} forceFetch - Whether to force fetch the data.
   * @returns {Promise<Array<Object>>} The rating types.
   */
  const fetchRatingTypes = async (forceFetch = false) => {
    const { schoolId } = useHelpers();

    if (!shouldFetch(hasFetched.value["ratingTypes"]) && !forceFetch) {
      return ratingTypes.value || [];
    }

    try {
      const {
        data: { data: fetchedRatingTypes },
      } = await axios.get(`/api/school/${schoolId}/rating-types`);

      hasFetched.value["ratingTypes"] = new Date();

      ratingTypes.value = fetchedRatingTypes;
      initialRatingTypes.value = cloneDeep(ratingTypes.value);

      return ratingTypes.value;
    } catch (error) {
      console.log("🚀 ~ fetchTeachers ~ error:", error);
      if (axios.isAxiosError(error)) {
        return Promise.reject(error);
      }
    }
  };

  /**
   * Store a rating type.
   * @param {Object} rating - The rating type to store.
   * @returns {Promise<Object>} The stored rating type.
   */
  const storeRatingType = async (rating) => {
    console.log("🚀 ~ storeRatingType ~ rating:", rating);
    try {
      const { data } = await axios.post(
        `/api/school/${schoolId}/rating-types`,
        rating,
      );

      console.log("🚀 ~ storeRatingType ~ data:", data);

      await fetchRatingTypes(true);

      // initialPortfolioTags.value.push(data);
      // portfolioTags.value.push(data);

      return data;
    } catch (error) {
      if (axios.isAxiosError(error)) {
        console.log(error);

        return Promise.reject(error.response);
      }
    }
  };

  /**
   * Update a rating type.
   * @param {Object} ratingType - The rating type to update.
   * @returns {Promise<Object>} The updated rating type.
   */
  const updateRatingType = async (ratingType) => {
    try {
      updatingData.value = true;

      const { id } = ratingType;

      const { data } = await axios.put(
        `/api/school/${schoolId}/rating-types/${id}`,
        ratingType,
      );

      const found = ratingTypes.value.find(({ id: _id }) => _id === id);
      const foundInitial = initialRatingTypes.value.find(
        ({ id: _id }) => _id === id,
      );

      if (found) {
        Object.assign(found, data);
      }

      if (foundInitial) {
        Object.assign(foundInitial, data);
      }

      return data;
    } catch (error) {
      console.log(error);
      if (axios.isAxiosError(error)) {
        return Promise.reject(error.response);
      }
    } finally {
      updatingData.value = false;
    }
  };

  /**
   * Delete a rating type.
   * @param {number} id - The ID of the rating type to delete.
   * @returns {Promise<Array<Object>>} The updated list of rating types.
   */
  const deleteRatingType = async (id) => {
    try {
      await axios.delete(`/api/school/${schoolId}/rating-types/${id}`);

      return await fetchRatingTypes(true);
    } catch (error) {
      console.log(error);
      if (axios.isAxiosError(error)) {
        console.log(error);

        return Promise.reject(error.response);
      }
    }
  };

  /**
   * Add portfolio tags.
   * @param {Object} tag - The tag to add.
   * @param {string} tag.name - The name of the tag.
   * @returns {Promise<Object>} The added tag.
   */
  const addPortfolioTags = async ({ name }) => {
    try {
      updatingData.value = true;

      const { data } = await axios.post(
        `/api/school/${schoolId}/portfolio-tags`,
        {
          name,
        },
      );

      initialPortfolioTags.value.push(data);
      portfolioTags.value.push(data);

      return data;
    } catch (error) {
      if (axios.isAxiosError(error)) {
        console.log(error);

        // Set the value back because the request failed
        Object.assign(
          portfolioTags,
          JSON.parse(JSON.stringify(initialPortfolioTags)),
        );

        return Promise.reject(error.response);
      }
    } finally {
      updatingData.value = false;
    }
  };

  /**
   * Update the education levels of a portfolio tag.
   * @param {Object} tag - The tag to update.
   * @param {number} tag.id - The ID of the tag.
   * @param {Array<number>} tag.education_levels - The education levels to update.
   * @returns {Promise<void>}
   */
  const updatePortfolioTagEducationLevels = async (tag) => {
    const { id, education_levels } = tag;

    try {
      await axios.post(
        `/api/school/${schoolId}/portfolio-tags/${id}/connect-education-levels`,
        { education_levels },
      );
    } catch (error) {
      if (axios.isAxiosError(error)) {
        return Promise.reject(error);
      }
    }
  };

  /**
   * Update the education levels of a portfolio skill.
   * @param {Object} skill - The skill to update.
   * @param {number} skill.id - The ID of the skill.
   * @param {Array<number>} skill.education_levels - The education levels to update.
   * @returns {Promise<void>}
   */
  const updatePortfolioSkillsEducationLevels = async (skill) => {
    const { id, education_levels } = skill;

    try {
      const { data } = await axios.post(
        `/api/school/${schoolId}/portfolio-skills/${id}/connect-education-levels`,
        { education_levels },
      );
    } catch (error) {
      if (axios.isAxiosError(error)) {
        return Promise.reject(error);
      }
    }
  };

  /**
   * Update a portfolio tag.
   * @param {Object} tag - The tag to update.
   * @param {number} tag.id - The ID of the tag.
   * @param {string} tag.name - The name of the tag.
   * @returns {Promise<Object>} The updated tag.
   */
  const updatePortfolioTag = async ({ id, name }) => {
    try {
      updatingData.value = true;

      const { data } = await axios.put(
        `/api/school/${schoolId}/portfolio-tags/${id}`,
        {
          id,
          name,
        },
      );

      const found = portfolioTags.value.find(
        ({ id: portfolioId }) => portfolioId === id,
      );

      if (found) {
        Object.assign(found, data);
      }

      return data;
    } catch (error) {
      console.log(error);
      if (axios.isAxiosError(error)) {
        console.log(error);

        // Set the value back because the request failed
        portfolioTags.value = JSON.parse(
          JSON.stringify(initialPortfolioTags.value),
        );

        return Promise.reject(error.response);
      }
    } finally {
      updatingData.value = false;
    }
  };

  /**
   * Delete a portfolio tag.
   * @param {Object} tag - The tag to delete.
   * @param {number} tag.id - The ID of the tag.
   * @returns {Promise<Array<Object>>} The updated list of portfolio tags.
   */
  const deletePortfolioTag = async ({ id }) => {
    try {
      updatingData.value = true;

      const response = await axios.delete(
        `/api/school/${schoolId}/portfolio-tags/${id}`,
        {
          id,
        },
      );

      portfolioTags.value = response.data.data;
      initialPortfolioTags.value = JSON.parse(
        JSON.stringify(response.data.data),
      );

      return response.data.data;
    } catch (error) {
      console.log(error);
      if (axios.isAxiosError(error)) {
        console.log(error);

        return Promise.reject(error.response);
      }
    } finally {
      updatingData.value = false;
    }
  };

  /**
   * Get the portfolio skills.
   * @param {Object} [options={}] - The options for fetching portfolio skills.
   * @param {boolean} [options.forceRefetch=false] - Whether to force refetch the data.
   * @returns {Promise<any>} The portfolio skills.
   */
  const getPortfolioSkills = async (options = {}) => {
    const { forceRefetch = false } = options;

    if (portfolioSkills.value.length !== 0 && !forceRefetch) {
      return;
    }

    try {
      fetchingData.value = true;

      const { data } = await axios.get(
        `/api/school/${schoolId}/portfolio-skills`,
      );

      data.data.forEach((skill) => {
        if (skill.education_levels === null) {
          skill.education_levels = [];
        }
      });

      portfolioSkills.value = data.data;
      initialPortfolioSkills.value = JSON.parse(JSON.stringify(data.data));

      return portfolioSkills;
    } catch (error) {
      if (axios.isAxiosError(error)) {
        console.log(error);
        return Promise.reject(error.response);
      }
    } finally {
      fetchingData.value = false;
    }
  };

  /**
   * Add portfolio skills.
   * @param {Object} skill - The skill to add.
   * @returns {Promise<any>} The added skill.
   */
  const addPortfolioSkills = async (skill) => {
    try {
      updatingData.value = true;

      const { data } = await axios.post(
        `/api/school/${schoolId}/portfolio-skills`,
        skill,
      );

      console.log(
        "🚀 ~ addPortfolioSkills ~ portfolioSkills.value:",
        portfolioSkills.value,
      );
      // Add the new skill to portfolioSkills
      portfolioSkills.value.push(data);

      // Keep the initial and current value in sync
      initialPortfolioSkills.value = JSON.parse(
        JSON.stringify(portfolioSkills.value),
      );

      return data;
    } catch (error) {
      if (axios.isAxiosError(error)) {
        console.log(error);

        // Set the value back because the request failed
        portfolioSkills.value = initialPortfolioSkills.value;

        return Promise.reject(error.response);
      }
    } finally {
      updatingData.value = false;
    }
  };

  /**
   * Update a portfolio skill.
   * @param {Object} skill - The skill to update.
   * @param {number} skill.id - The ID of the skill.
   * @param {string} skill.name - The name of the skill.
   * @param {string} skill.description - The description of the skill.
   * @param {number} [skill.category_id] - The category ID of the skill.
   * @returns {Promise<any>} The updated skill.
   */
  const updatePortfolioSkill = async ({
    id,
    name,
    description,
    category_id,
  }) => {
    const updateObject = {
      id,
      name,
      description,
    };

    if (usePortfolioCategories.value) {
      updateObject.category_id = category_id;
    }

    try {
      updatingData.value = true;

      const response = await axios.put(
        `/api/school/${schoolId}/portfolio-skills/${id}`,
        {
          ...updateObject,
        },
      );

      return response.data;
    } catch (error) {
      console.log(error);
      if (axios.isAxiosError(error)) {
        console.log(error);

        // Set the value back because the request failed
        portfolioSkills.value = JSON.parse(
          JSON.stringify(initialPortfolioSkills.value),
        );

        return Promise.reject(error.response);
      }
    } finally {
      updatingData.value = false;
    }
  };

  /**
   * Delete a portfolio skill.
   * @param {Object} skill - The skill to delete.
   * @param {number} skill.id - The ID of the skill.
   * @returns {Promise<any>} The deleted skill data.
   */
  const deletePortfolioSkill = async ({ id }) => {
    try {
      updatingData.value = true;

      const response = await axios.delete(
        `/api/school/${schoolId}/portfolio-skills/${id}`,
        {
          id,
        },
      );

      portfolioSkills.value = response.data.data;
      initialPortfolioSkills.value = JSON.parse(
        JSON.stringify(response.data.data),
      );

      return response.data.data;
    } catch (error) {
      console.log(error);
      if (axios.isAxiosError(error)) {
        console.log(error);

        return Promise.reject(error.response);
      }
    } finally {
      updatingData.value = false;
    }
  };

  /**
   * Get the portfolio categories.
   * @param {Object} [options={}] - The options for fetching portfolio categories.
   * @param {boolean} [options.forceRefetch=false] - Whether to force refetch the data.
   * @returns {Promise<any>} The portfolio categories.
   */
  const getPortfolioCategories = async (options = {}) => {
    const { forceRefetch = false } = options;

    if (portfolioCategories.value.length !== 0 && !forceRefetch) {
      return;
    }

    try {
      fetchingData.value = true;

      const { data } = await axios.get(
        `/api/school/${schoolId}/portfolio-skill-categories`,
      );

      portfolioCategories.value = data.data;
      initialPortfolioCategories.value = JSON.parse(JSON.stringify(data.data));

      return portfolioCategories;
    } catch (error) {
      if (axios.isAxiosError(error)) {
        console.log(error);
        return Promise.reject(error.response);
      }
    } finally {
      fetchingData.value = false;
    }
  };

  /**
   * Add a portfolio category.
   * @param {Object} category - The category to add.
   * @param {string} category.name - The name of the category.
   * @returns {Promise<any>} The added category.
   */
  const addPortfolioCategory = async ({ name }) => {
    try {
      updatingData.value = true;

      const response = await axios.post(
        `/api/school/${schoolId}/portfolio-skill-categories`,
        {
          name,
        },
      );

      portfolioCategories.value.push(response.data);
      initialPortfolioCategories.value.push(
        JSON.parse(JSON.stringify(response.data)),
      );

      return response.data;
    } catch (error) {
      if (axios.isAxiosError(error)) {
        console.log(error);

        // Set the value back because the request failed
        portfolioCategories.value = initialPortfolioCategories.value;

        return Promise.reject(error.response);
      }
    } finally {
      updatingData.value = false;
    }
  };

  /**
   * Update a portfolio category.
   * @param {Object} category - The category to update.
   * @param {number} category.id - The ID of the category.
   * @param {string} category.name - The name of the category.
   * @returns {Promise<any>} The updated category.
   */
  const updatePortfolioCategory = async ({ id, name }) => {
    try {
      updatingData.value = true;

      const response = await axios.put(
        `/api/school/${schoolId}/portfolio-skill-categories/${id}`,
        {
          id,
          name,
        },
      );

      return response.data;
    } catch (error) {
      console.log(error);
      if (axios.isAxiosError(error)) {
        console.log(error);

        // Set the value back because the request failed
        portfolioCategories.value = JSON.parse(
          JSON.stringify(initialPortfolioCategories.value),
        );

        return Promise.reject(error.response);
      }
    } finally {
      updatingData.value = false;
    }
  };

  /**
   * Delete a portfolio category.
   * @param {Object} category - The category to delete.
   * @param {number} category.id - The ID of the category.
   * @returns {Promise<any>} The deleted category data.
   */
  const deletePortfolioCategory = async ({ id }) => {
    try {
      updatingData.value = true;

      const response = await axios.delete(
        `/api/school/${schoolId}/portfolio-skill-categories/${id}`,
        {
          id,
        },
      );

      portfolioCategories.value = response.data.data;
      initialPortfolioCategories.value = JSON.parse(
        JSON.stringify(response.data.data),
      );

      return response.data.data;
    } catch (error) {
      console.log(error);
      if (axios.isAxiosError(error)) {
        console.log(error);

        return Promise.reject(error.response);
      }
    } finally {
      updatingData.value = false;
    }
  };

  /**
   * Update user settings.
   * @param {Object} settings - The settings to update.
   * @param {string} settings.key - The key of the setting.
   * @param {any} settings.val - The value of the setting.
   * @returns {Promise<any>} The updated settings.
   */
  const updateUserSettings = async ({ key, val }) => {
    try {
      updatingData.value = true;

      const response = await axios.put(`/api/settings`, {
        field: key,
        value: val,
      });

      // Update initialSchoolSettings as it's successfully updated
      Object.assign(
        initialUserSettings,
        JSON.parse(JSON.stringify(response.data.data)),
      );

      return response.data;
    } catch (error) {
      if (axios.isAxiosError(error)) {
        console.log(error);

        // Set the value back because the request failed
        Object.assign(
          userSettings,
          JSON.parse(JSON.stringify(initialUserSettings)),
        );

        return Promise.reject(error.response);
      }
    } finally {
      updatingData.value = false;
    }
  };

  /**
   * Get the schools without new processing agreement.
   * @returns {Promise<{length}|T|any>} The schools without new processing agreement.
   */
  const getSchoolsWithoutNewProcessingAgreement = async () => {
    try {
      if (schools_without_new_processing_agreement.value.length) {
        return schools_without_new_processing_agreement.value;
      }

      fetchingData.value = true;

      const { data } = await axios.get(
        `/api/school/${schoolId}/temporary-school-grades-table`,
      );

      schools_without_new_processing_agreement.value = data;

      return data;
    } catch (error) {
      if (axios.isAxiosError(error)) {
        console.log(error);
        return Promise.reject(error.response);
      }
    } finally {
      fetchingData.value = false;
    }
  };

  /**
   * Check if the school has a new processing agreement.
   * @type {ComputedRef<boolean>}
   */
  const schoolHasNewProcessingAgreement = computed(() => {
    return !schools_without_new_processing_agreement.value.includes(
      parseInt(schoolId),
    );
  });

  /**
   * Update the school settings.
   * @param {string} key - The key of the setting.
   * @param {any} val - The value of the setting.
   * @param {boolean} [root=false] - Whether to update the root settings.
   * @returns {Promise<any>} The updated settings.
   */
  const updateSettings = async (key, val, root = false) => {
    try {
      updatingData.value = true;

      let endpoint = `/api/school/${schoolId}/settings`;

      if (root) {
        endpoint = `/api/school/${schoolId}/settings/root`;
      }

      const response = await axios.put(endpoint, {
        field: key,
        value: val,
      });

      // Update initialSchoolSettings as it's successfully updated
      Object.assign(
        initialSchoolSettings,
        JSON.parse(JSON.stringify(response.data.data)),
      );

      return response.data;
    } catch (error) {
      if (axios.isAxiosError(error)) {
        console.log(error);

        // Set the value back because the request failed
        Object.assign(
          schoolSettings,
          JSON.parse(JSON.stringify(initialSchoolSettings)),
        );

        return Promise.reject(error.response);
      }
    } finally {
      updatingData.value = false;
    }
  };

  /**
   * Update the school's auth\_source.
   * @param {number|string} value - The new auth\_source value.
   * @returns {Promise<any>} The updated auth\_source.
   */
  const updateAuthSource = async (value) => {
    try {
      updatingData.value = true;

      let formData = new FormData();
      formData.set("sso_authsource", value);

      await axios.post(
        `/api/school/${schoolSettings.school_id}/update-authsource`,
        formData,
      );

      restrictedSchoolSettings.sso_authsource = value;

      // Update initialSchoolSettings as it's successfully updated
      initialRestrictedSchoolSettings.sso_authsource = value;
    } catch (error) {
      if (axios.isAxiosError(error)) {
        console.log(error);

        return Promise.reject(error.response);
      }
    } finally {
      updatingData.value = false;
    }
  };

  /**
   * Update the email settings.
   * @param {string} type - The type of the email setting.
   * @param {string} value - The value of the email setting.
   * @returns {Promise<any>} The updated email settings.
   */
  const updateEmailSettings = async (type, value) => {
    const formData = new FormData();
    formData.set("type", type);
    formData.set("value", value);

    try {
      updatingData.value = true;

      await axios.post(`/api/school/${schoolId}/email-settings`, formData);

      return Promise.resolve();
    } catch (error) {
      if (axios.isAxiosError(error)) {
        return Promise.reject(error);
      }
    } finally {
      updatingData.value = false;
    }
  };

  return {
    schoolSettings,
    restrictedSchoolSettings,
    initialSchoolSettings,
    initialRestrictedSchoolSettings,
    userSettings,
    fetchingData,
    updatingData,
    storeRatingType,
    deleteRatingType,
    updateRatingType,
    usePortfolioCategories,
    emailFormList,
    initialPortfolioTags,
    fetchRatingTypes,
    ratingTypes,
    initialRatingTypes,
    educationLevels,
    portfolioTags,
    portfolioSkills,
    initialPortfolioSkills,
    portfolioCategories,
    initialPortfolioCategories,
    portfolioBanners,
    initialUserSettings,
    getSchoolSettings,
    getPortfolioTags,
    getPortfolioCategories,
    addPortfolioCategory,
    updatePortfolioCategory,
    deletePortfolioCategory,
    getRestrictedSchoolSettings,
    getEducationLevels,
    getUserSettings,
    updateUserSettings,
    updatePortfolioTag,
    deletePortfolioTag,
    getSchoolsWithoutNewProcessingAgreement,
    addPortfolioTags,
    schoolHasNewProcessingAgreement,
    updateSettings,
    updateEmailSettings,
    updateAuthSource,
    getPortfolioSkills,
    addPortfolioSkills,
    updatePortfolioSkill,
    deletePortfolioSkill,
    updatePortfolioTagEducationLevels,
    updatePortfolioSkillsEducationLevels,
    allowedFileTypes,
    gradesEnabled,
  };
});
