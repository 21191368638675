import axios from "axios";
import { acceptHMRUpdate, defineStore } from "pinia";
import { ref, computed } from "vue";
import useHelpers from "composables/useHelpers";
import { shouldFetch } from "common/fetchHelpers.js";

/**
 * Defines a Pinia store for managing student-related data and operations.
 * @module useStudentStore
 */
export const useStudentStore = defineStore("student", () => {
  // Helpers
  const { schoolId } = useHelpers();

  // Refs
  const students = ref([]);
  const schedulesByStudent = ref({});
  const student = ref(null);
  const activeStudent = ref({});

  // States
  const isFetching = ref({
    general_student_info: false,
  });

  const hasFetched = ref({
    general_student_info: false,
  });

  // Computed

  /**
   * Computes a sorted list of unique mentor classes from the student data.
   * @returns {Array<string>} List of unique mentor classes.
   */
  const mentorClasses = computed(() => {
    return [
      ...new Set(students.value.flatMap(({ mentor_class }) => mentor_class)),
    ].sort();
  });

  /**
   * Computes a sorted list of unique schedules across all students.
   * @returns {Array<Object>} List of unique schedules.
   */
  const schedules = computed(() => {
    return [
      ...new Set(
        Object.values(schedulesByStudent.value).flatMap((schedule) => schedule),
      ),
    ].sort();
  });

  // Actions

  /**
   * Fetches student data from the API, with an optional parameter to fetch schedules.
   * @param {boolean} doFetchSchedules - Whether to fetch student schedules after fetching students.
   * @returns {Promise<Array<Object>>} List of students.
   */
  const fetchStudents = async (doFetchSchedules = false) => {
    try {
      const { data } = await axios.get(`/api/school/${schoolId}/students`, {
        params: { withMentors: true },
      });

      data.map((student) => {
        // TODO: Let this data come from the backend
        const nameList = [
          ...(student.first_name ? [student.first_name] : []),
          ...(student.middle_name ? [student.middle_name] : []),
          ...(student.last_name ? [student.last_name] : []),
        ];
        student.formattedName = nameList.join(" ");
        student.schedules = [];

        const { education_level, education_year } = student;
        student.formattedEducationLevel = `${education_level} ${education_year}`;
      });

      students.value = data;

      if (doFetchSchedules) {
        await fetchSchedules();
      }

      return data;
    } catch (error) {
      if (axios.isAxiosError(error)) {
        return Promise.reject(error);
      }
    }
  };

  /**
   * Fetches student schedules from the API and associates them with the correct students.
   * @returns {Promise<void>}
   */
  const fetchSchedules = async () => {
    try {
      const { data } = await axios.get(
        `/api/school/${schoolId}/students-with-schedules`,
      );

      schedulesByStudent.value = data;

      Object.entries(data).forEach(([student_id, studentSchedules]) => {
        const foundStudent = students.value.find(({ id }) => id == student_id);
        if (foundStudent) {
          foundStudent.schedules = studentSchedules;
        }
      });
    } catch (error) {
      console.log("🚀 ~ fetchSchedules ~ error:", error);
      if (axios.isAxiosError(error)) {
        return Promise.reject(error);
      }
    }
  };

  /**
   * Fetches a specific student's data by their ID.
   * @param {number} id - The ID of the student to fetch.
   * @returns {Promise<Object>} The student's data.
   */
  const fetchStudent = async (id) => {
    try {
      const { data } = await axios.get(
        `/api/school/${schoolId}/students/${id}`,
      );

      student.value = data;
      return data;
    } catch (error) {
      if (axios.isAxiosError(error)) {
        return Promise.reject(error);
      }
    }
  };

  /**
   * Fetches general information about a student by their ID.
   * Can be forced to re-fetch data even if it was previously fetched.
   * @param {Object} options - Options object.
   * @param {boolean} options.forceFetch - Whether to force a re-fetch of data.
   * @param {number} options.studentId - The ID of the student to fetch.
   * @returns {Promise<Object>} The fetched student data or the current active student data.
   */
  const fetchGeneralStudentInfo = async ({
    forceFetch = false,
    studentId = null,
  } = {}) => {
    if (!studentId) {
      return Promise.reject("No student id provided");
    }

    if (isFetching.value["general_student_info"]) {
      return;
    }

    isFetching.value["general_student_info"] = true;

    if (!shouldFetch(hasFetched.value["general_student_info"]) && !forceFetch) {
      return activeStudent.value || [];
    }

    try {
      const { data: fetchedData } = await axios.get(
        `/api/single-student/${studentId}`,
      );

      hasFetched.value["general_student_info"] = new Date();
      activeStudent.value = fetchedData;

      return activeStudent.value;
    } catch (error) {
      if (axios.isAxiosError(error)) {
        console.log(error);
        return Promise.reject(error.response);
      }
    } finally {
      isFetching.value["general_student_info"] = false;
    }
  };

  /**
   * Creates a new student in the system.
   * @param {Object} student - The student data to create.
   * @param {number} schoolId - The ID of the school where the student is being added.
   * @returns {Promise<Object>} The created student's data.
   */
  const createStudent = async (student, schoolId) => {
    try {
      const { data } = await axios.post(
        `/api/school/${schoolId}/students`,
        student,
      );

      return data;
    } catch (error) {
      if (axios.isAxiosError(error)) {
        return Promise.reject(error);
      }
    }
  };

  /**
   * Updates an existing student's data.
   * @param {Object} student - The student data to update.
   * @returns {Promise<Object>} The updated student data.
   */
  const updateStudent = async (student) => {
    try {
      const { data } = await axios.put(`/api/students/${student.id}`, student);

      return Promise.resolve(data);
    } catch (error) {
      if (axios.isAxiosError(error)) {
        return Promise.reject(error);
      }
    }
  };

  /**
   * Gets a student's formatted name by their ID.
   * @param {number} id - The ID of the student.
   * @returns {string} The formatted name of the student, or an empty string if not found.
   */
  const getStudentNameById = (id) => {
    const student = students.value.find((student) => student.id === id);
    return student ? student.formattedName : "";
  };

  return {
    students,
    student,
    schedules,
    schedulesByStudent,
    mentorClasses,
    activeStudent,
    fetchStudents,
    fetchStudent,
    createStudent,
    updateStudent,
    fetchSchedules,
    getStudentNameById,
    fetchGeneralStudentInfo,
  };
});

if (import.meta.hot) {
  import.meta.hot.accept(acceptHMRUpdate(useStudentStore, import.meta.hot));
}
