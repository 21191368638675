import axios from "axios";
import { acceptHMRUpdate, defineStore, storeToRefs } from "pinia";
import { computed, ref } from "vue";
import useHelpers from "composables/useHelpers";
import { useRouter } from "vue-router";
import { shouldFetch } from "common/fetchHelpers.js";
import { useQuasar } from "quasar";
import { cloneDeep } from "lodash";
import { useOverviewStore } from "./overviewStore";

/**
 * Meeting Store
 *
 * This store manages the state and actions related to meetings.
 */
export const useMeetingStore = defineStore("meeting", () => {
  // Router
  const router = useRouter();
  const $q = useQuasar();

  // Refs
  const user = ref(JSON.parse(localStorage.getItem("user")));
  const meeting = ref({});
  const initialMeeting = ref({});
  const initialStudents = ref([]);
  const initialSchedules = ref([]);
  const teachers = ref([]);
  const meetings = ref([]);
  const myStudentsGroupMeetings = ref([]);
  const myLessongroupsGroupMeetings = ref([]);

  const hasFetched = ref({
    teachers: false,
    meetings: false,
    myStudentsGroupMeetings: false,
    myLessongroupsGroupMeetings: false,
  });

  // Computed
  /**
   * Determines if the user is allowed to perform certain actions.
   * @returns {boolean}
   */
  const isAllowed = computed(() => {
    const allowedRoles = ["manager", "webmaster", "organizer"];
    return user.value.role.some((role) => allowedRoles.includes(role));
  });

  /**
   * Determines if the current route is an overview.
   * @returns {boolean}
   */
  const isOverview = computed(() => {
    return !!router.currentRoute.value.query.overview;
  });

  /**
   * Determines if the current meeting is editable.
   * @returns {boolean}
   */
  const isEditable = computed(() => {
    if (!meeting.value?.data) {
      // Return true because a new meeting is created
      return true;
    }

    const statusCanEdit = {
      group_meeting: ["concept", "open"],
      transition_meeting: ["concept", "open", "closed_modify"],
    };

    return statusCanEdit[meeting.value.data.meeting_type].includes(
      meeting.value.data.status,
    );
  });

  /**
   *
   * Determines if the current meeting is an existing meeting.
   * @returns {boolean}
   */
  const isExistingMeeting = computed(() => {
    return !!meeting.value?.data?.id;
  });

  /**
   * Configuration for group meetings.
   * @returns {Object}
   */
  const groupMeetingConfig = computed(() => {
    const ratingLabels = {
      1: "Excellent",
      2: "Ga zo door",
      3: "Pas op",
      4: "Let op",
      5: "Geen beoordeling",
    };

    const backgroundColors = {
      1: "#000",
      2: "#72a886",
      3: "#fcb654",
      4: "#cc5c5c",
      5: "#ccc",
    };

    return {
      ratingLabels,
      backgroundColors,
    };
  });

  // Actions
  /**
   * Fetches the list of meetings.
   * @param {string|null} teacherShortage - The teacher shortage parameter.
   * @returns {Promise<Array>} The fetched meetings.
   */
  const fetchMeetings = async (teacherShortage = null) => {
    const { employeeId } = useHelpers();

    try {
      const { data: fetchedMeetings } = await axios.get(
        `/api/teacher/${employeeId}/ratings`,
        {
          params: {
            ...(teacherShortage && { shortage: teacherShortage }),
          },
        },
      );

      meetings.value = fetchedMeetings;

      return meetings.value;
    } catch (error) {
      console.log("🚀 ~ fetchMeetings ~ error:", error);
      if (axios.isAxiosError(error)) {
        return Promise.reject(error);
      }
    }
  };

  /**
   * Stores a student meeting.
   * @returns {Promise<void>}
   */
  const storeStudentMeeting = async () => {
    const { schoolId } = useHelpers();

    try {
      let formData = new FormData();
      formData.set("meeting", JSON.stringify(meeting.value));

      await axios.post(`/api/school/${schoolId}/meetings`, formData);
    } catch (error) {
      console.log("🚀 ~ storeStudentMeeting ~ error:", error);
      if (axios.isAxiosError(error)) {
        console.log("🚀 ~ storeMeeting ~ error:", error);
        return Promise.reject(error);
      }
    }
  };

  /**
   * Stores a transition meeting.
   * @returns {Promise<void>}
   */
  const storeTransitionMeeting = async () => {
    const { schoolId } = useHelpers();

    try {
      let formData = new FormData();
      formData.set("meeting", JSON.stringify(meeting.value));

      await axios.post(`/api/school/${schoolId}/transition-meetings`, formData);
    } catch (error) {
      console.log("🚀 ~ storeStudentMeeting ~ error:", error);
      if (axios.isAxiosError(error)) {
        console.log("🚀 ~ storeMeeting ~ error:", error);
        return Promise.reject(error);
      }
    }
  };

  /**
   * Stores a group meeting.
   * @returns {Promise<void>}
   */
  const storeGroupMeeting = async () => {
    const { schoolId } = useHelpers();

    try {
      console.log("🚀 ~ storeGroupMeeting ~ meeting.value:", meeting.value);
      await axios.post(`/api/school/${schoolId}/group-meetings`, meeting.value);
    } catch (error) {
      console.log("🚀 ~ storeGroupMeeting ~ error:", error);
      if (axios.isAxiosError(error)) {
        return Promise.reject(error);
      }
    }
  };

  /**
   * Fetches a transition meeting by its ID.
   * @param {number} meetingId - The ID of the meeting to fetch.
   * @returns {Promise<Object>} The fetched meeting.
   */
  const fetchTransitionMeeting = async (meetingId) => {
    try {
      const { data } = await axios.get(`/api/transition-meetings/${meetingId}`);

      meeting.value = data;

      return data;
    } catch (error) {
      console.log("🚀 ~ fetchTransitionMeeting ~ error:", error);

      if (axios.isAxiosError(error)) {
        return Promise.reject(error);
      }
    }
  };

  /**
   * Fetches a group meeting by its ID.
   * @param {number} meetingId - The ID of the meeting to fetch.
   * @returns {Promise<Object>} The fetched meeting.
   */
  const fetchGroupMeeting = async (meetingId) => {
    const { schoolId } = useHelpers();

    try {
      const { data } = await axios.get(
        `/api/school/${schoolId}/group-meetings/${meetingId}`,
      );

      meeting.value = { data };
      initialMeeting.value = cloneDeep(meeting.value);

      return { data };
    } catch (error) {
      console.log("🚀 ~ fetchGroupMeeting ~ error:", error);

      if (axios.isAxiosError(error)) {
        return Promise.reject(error);
      }
    }
  };

  /**
   * Fetches the list of teachers.
   * @param {boolean} forceFetch - Whether to force fetch the data.
   * @returns {Promise<Array>} The fetched teachers.
   */
  const fetchTeachers = async (forceFetch = false) => {
    const { schoolId } = useHelpers();

    if (!shouldFetch(hasFetched.value["teachers"]) && !forceFetch) {
      return teachers.value || [];
    }

    try {
      const { data } = await axios.get(
        `/api/school/${schoolId}/group-meetings/teachers`,
      );

      hasFetched.value["teachers"] = new Date();

      teachers.value = data.map(
        ({ teacher_id, teacher_shortage, mentor_classes }) => {
          return {
            teacher_id,
            teacher_shortage,
            mentor_classes: mentor_classes.filter(
              ({ percentage }) => percentage > 0,
            ),
          };
        },
      );

      return teachers.value;
    } catch (error) {
      console.log("🚀 ~ fetchTeachers ~ error:", error);
      if (axios.isAxiosError(error)) {
        return Promise.reject(error);
      }
    }
  };

  /**
   * Removes a meeting.
   * @param {Object} meeting - The meeting to remove.
   * @returns {Promise<void>}
   */
  const removeMeeting = async (meeting) => {
    const { schoolId } = useHelpers();

    try {
      const { meeting_type, id } = meeting.data;
      let url;

      switch (meeting_type) {
        case "group_meeting":
          url = `/api/school/${schoolId}/group-meetings/${id}`;
          break;
        case "transition_meeting":
          url = `/api/transition-meetings/${id}`;
          break;
        case "meeting":
          url = `/api/meetings/${id}`;
          break;
      }

      return await axios.delete(url);
    } catch (error) {
      console.log("🚀 ~ storeStudentMeeting ~ error:", error);
      if (axios.isAxiosError(error)) {
        console.log("🚀 ~ storeMeeting ~ error:", error);
        return Promise.reject(error);
      }
    }
  };

  /**
   * Updates a meeting.
   * @param {Object} meeting - The meeting to update.
   * @returns {Promise<void>}
   */
  const updateMeeting = async (meeting) => {
    try {
      const { meeting_type, id } = meeting.value.data;
      let url;

      switch (meeting_type) {
        case "transition_meeting":
          url = `/api/transition-meetings/${id}/update-global-info`;
          break;
        case "meeting":
          url = `/api/meetings/${id}/update-global-info`;
          break;
      }

      return await axios.post(url, meeting.value.data);
    } catch (error) {
      console.log("🚀 ~ storeStudentMeeting ~ error:", error);
      if (axios.isAxiosError(error)) {
        console.log("🚀 ~ storeMeeting ~ error:", error);
        return Promise.reject(error);
      }
    }
  };

  /**
   * Updates the selected teachers for a group meeting.
   * @param {Object} meeting - The meeting to update.
   * @returns {Promise<void>}
   */
  const updateGroupMeetingSelectedTeachers = async (meeting) => {
    const { schoolId } = useHelpers();

    try {
      const { id, teacher_ids, teacher_percentage } = meeting.data;
      console.log(
        "🚀 ~ updateGroupMeetingSelectedTeachers ~ meeting.data:",
        meeting.data,
      );

      await axios.post(
        `api/school/${schoolId}/group-meetings/${id}/update-ratings`,
        { teacher_ids, teacher_percentage },
      );

      await fetchGroupMeeting(id);
    } catch (error) {
      console.log("🚀 ~ storeStudentMeeting ~ error:", error);
      if (axios.isAxiosError(error)) {
        console.log("🚀 ~ storeMeeting ~ error:", error);
        return Promise.reject(error);
      }
    }
  };

  /**
   * Clears the share feedback date and time.
   */
  const clearShareFeedbackAt = () => {
    if (meeting?.value?.data?.timeframes) {
      meeting.value.data.timeframes.share_feedback_at_date = null;
      meeting.value.data.timeframes.share_feedback_at_time = null;
      meeting.value.data.timeframes.auto_share = 0;
    }

    if (meeting?.value?.timeframe_settings) {
      meeting.value.timeframe_settings.share_feedback_at_date = null;
      meeting.value.timeframe_settings.share_feedback_at_time = null;
      meeting.value.timeframe_settings.auto_share = 0;
    }
  };

  /**
   * Updates students and subjects for a meeting.
   * @param {Object} meeting - The meeting to update.
   * @returns {Promise<axios.AxiosResponse<any>>}
   */
  const updateStudentsAndSubjects = async (meeting) => {
    try {
      const { id } = meeting.value.data;

      let formData = new FormData();
      formData.set("meeting", JSON.stringify(meeting.value.data));

      return await axios.post(
        `/api/transition-meetings/${id}/update-students-and-subjects`,
        formData,
      );
    } catch (error) {
      console.log("🚀 ~ storeStudentMeeting ~ error:", error);
      if (axios.isAxiosError(error)) {
        console.log("🚀 ~ storeMeeting ~ error:", error);
        return Promise.reject(error);
      }
    }
  };

  /**
   * Updates the rating for a group meeting.
   * @param {Object} params - The parameters.
   * @param {Object} params.rating - The rating to update.
   * @param {number} params.meetingId - The ID of the meeting.
   * @returns {Promise<void>}
   */
  const updateGroupMeetingRating = async ({ rating, meetingId } = {}) => {
    const { schoolId } = useHelpers();

    return await axios.put(
      `/api/school/${schoolId}/group-meetings/${meetingId}/rating/${rating.id}`,
      {
        rating: rating.rating,
        rating_remark: rating.rating_remark,
      },
    );
  };

  /**
   * Updates the planning for a meeting.
   * @param {Object} meeting - The meeting to update.
   * @returns {Promise<void>}
   */
  const updatePlanning = async (meeting) => {
    const { schoolId } = useHelpers();

    try {
      const { meeting_type, id, timeframe_settings } = meeting.value.data;
      let url;

      switch (meeting_type) {
        case "group_meeting":
          url = `api/school/${schoolId}/group-meetings/${id}/update-planning`;

          return await axios.post(url, {
            timeframe_settings: timeframe_settings,
          });
        case "transition_meeting":
          url = `/api/transition-meetings/${id}/update-planning`;
          break;
        case "meeting":
          url = `/api/meetings/${id}/update-planning`;
          break;
      }

      const formData = new FormData();
      formData.set("timeframe_settings", JSON.stringify(timeframe_settings));

      return await axios.post(url, formData);
    } catch (error) {
      console.log("🚀 ~ storeStudentMeeting ~ error:", error);
      if (axios.isAxiosError(error)) {
        console.log("🚀 ~ storeMeeting ~ error:", error);
        return Promise.reject(error);
      }
    }
  };

  /**
   * Updates the rating types for a group meeting.
   * @param {Object} meeting - The meeting to update.
   * @returns {Promise<void>}
   */
  const updateGroupMeetingRatingTypes = async (meeting) => {
    const { schoolId } = useHelpers();

    try {
      const { id, rating_type_ids } = meeting.data;

      const { data } = await axios.post(
        `api/school/${schoolId}/group-meetings/${id}/update-rating-types`,
        { rating_type_ids },
      );

      await fetchGroupMeeting(id);

      return data;
    } catch (error) {
      console.log("🚀 ~ updateGroupMeetingRatingTypes ~ error:", error);
      if (axios.isAxiosError(error)) {
        console.log("🚀 ~ storeMeeting ~ error:", error);
        return Promise.reject(error);
      }
    }
  };

  /**
   * Fetches the group meetings for the user's students.
   * @param {boolean} forceFetch - Whether to force fetch the data.
   * @returns {Promise<Array>} The fetched group meetings.
   */
  const fetchMyStudentsGroupMeetings = async (forceFetch = false) => {
    const { employeeId } = useHelpers();

    if (
      !shouldFetch(hasFetched.value["myStudentsGroupMeetings"]) &&
      !forceFetch
    ) {
      return myStudentsGroupMeetings.value || [];
    }

    try {
      const { data } = await axios.get(
        `api/teacher/${employeeId}/mentor-classes/group-meetings`,
      );

      hasFetched.value["myStudentsGroupMeetings"] = new Date();

      myStudentsGroupMeetings.value = data.data;

      return data;
    } catch (error) {
      console.log("🚀 ~ fetchTransitionMeeting ~ error:", error);

      if (axios.isAxiosError(error)) {
        return Promise.reject(error);
      }
    }
  };

  /**
   * Fetches the group meetings for the user's lesson groups.
   * @param {boolean} forceFetch - Whether to force fetch the data.
   * @returns {Promise<Array>} The fetched group meetings.
   */
  const fetchMyLessongroupsGroupMeetings = async (forceFetch = false) => {
    const { employeeId } = useHelpers();

    if (
      !shouldFetch(hasFetched.value["myLessongroupsGroupMeetings"]) &&
      !forceFetch
    ) {
      return myLessongroupsGroupMeetings.value || [];
    }

    try {
      const { data } =
        await axios.get(`api/teacher/${employeeId}/lesson-groups/group-meetings
 `);

      hasFetched.value["myLessongroupsGroupMeetings"] = new Date();
      myLessongroupsGroupMeetings.value = data.data;

      return data;
    } catch (error) {
      console.log("🚀 ~ fetchTransitionMeeting ~ error:", error);

      if (axios.isAxiosError(error)) {
        return Promise.reject(error);
      }
    }
  };

  /**
   * Adds a goal to a group meeting.
   * @param {Object} params - The parameters.
   * @param {number} params.meetingId - The ID of the meeting.
   * @param {Object} params.goal - The goal to add.
   * @param {string} params.mentorClass - The mentor class.
   * @returns {Promise<Object>} The added goal.
   */
  const addGroupMeetingGoal = async ({ meetingId, goal, mentorClass } = {}) => {
    const { schoolId } = useHelpers();

    try {
      const { data } = await axios.post(
        `/api/school/${schoolId}/group-meetings/${meetingId}/agreements`,
        {
          mentor_class: mentorClass,
          type: "goal",
          value: goal,
        },
      );

      const meeting = myStudentsGroupMeetings.value.find(
        (groupMeeting) => groupMeeting.id === meetingId,
      );

      const mentorclass = meeting.mentor_classes.find(
        (mClass) => mClass.title === mentorClass,
      );

      mentorclass.goals = data;

      return data;
    } catch (error) {
      console.log(error);
      $q.notify({
        color: "negative",
        message: `${
          error.response?.data?.message ??
          "Er is iets misgegaan bij het toevoegen van het doel"
        }`,
      });
      console.log("🚀 ~ addGroupMeetingAgreement ~ error:", error);
      if (axios.isAxiosError(error)) {
        return Promise.reject(error);
      }
    }
  };

  /**
   * Deletes a goal from a group meeting.
   * @param {Object} params - The parameters.
   * @param {number} params.meetingId - The ID of the meeting.
   * @param {number} params.id - The ID of the goal to delete.
   * @returns {Promise<void>}
   */
  const deleteGroupMeetingGoal = async ({ meetingId, id } = {}) => {
    const { schoolId } = useHelpers();

    try {
      const { data } = await axios.delete(
        `/api/school/${schoolId}/group-meetings/${meetingId}/agreements/${id}`,
      );

      // Find the meeting and mentor class
      const meeting = myStudentsGroupMeetings.value.find(
        (groupMeeting) => groupMeeting.id === meetingId,
      );

      if (meeting) {
        meeting.mentor_classes.forEach((mentorClass) => {
          const goalIndex = mentorClass.goals.data.findIndex(
            (goal) => goal.id === id,
          );

          if (goalIndex !== -1) {
            mentorClass.goals = data;
          }
        });
      }

      $q.notify({
        color: "positive",
        message: `Het doel is succesvol verwijderd`,
      });
    } catch (error) {
      $q.notify({
        color: "negative",
        message: `${
          error.response?.data?.message ??
          "Er is iets misgegaan bij het verwijderen van het doel"
        }`,
      });
      console.log("🚀 ~ deleteGroupMeetingAgreement ~ error:", error);
      if (axios.isAxiosError(error)) {
        return Promise.reject(error);
      }
    }
  };

  /**
   * Updates a goal in a group meeting.
   * @param {Object} params - The parameters.
   * @param {number} params.meetingId - The ID of the meeting.
   * @param {number} params.id - The ID of the goal to update.
   * @param {string} params.value - The new value of the goal.
   * @returns {Promise<Object>} The updated goal.
   */
  const updateGroupMeetingGoal = async ({ meetingId, id, value } = {}) => {
    const { schoolId } = useHelpers();

    try {
      const { data } = await axios.put(
        `/api/school/${schoolId}/group-meetings/${meetingId}/agreements/${id}`,
        {
          value: value,
        },
      );

      // Find the meeting and mentor class
      const meeting = myStudentsGroupMeetings.value.find(
        (groupMeeting) => groupMeeting.id === meetingId,
      );

      if (meeting) {
        meeting.mentor_classes.forEach((mentorClass) => {
          const goalIndex = mentorClass.goals.data.findIndex(
            (goal) => goal.id === id,
          );

          if (goalIndex !== -1) {
            mentorClass.goals = data;
          }
        });
      }

      $q.notify({
        color: "positive",
        message: `Het doel is succesvol aangepast`,
      });

      return data;
    } catch (error) {
      $q.notify({
        color: "negative",
        message: `${
          error.response?.data?.message ??
          "Er is iets misgegaan bij het aanpassen van het doel"
        }`,
      });
      console.log("🚀 ~ updateGroupMeetingAgreement ~ error:", error);
      if (axios.isAxiosError(error)) {
        return Promise.reject(error);
      }
    }
  };

  /**
   * Finds a mentor class in a group meeting.
   * @param {Object} model - The model containing group meetings.
   * @param {number} groupMeetingId - The ID of the group meeting.
   * @param {string} mentorClassTitle - The title of the mentor class.
   * @returns {Object|Error} The found mentor class or an error if not found.
   */
  const findMentorClassInGroupMeeting = (
    model,
    groupMeetingId,
    mentorClassTitle,
  ) => {
    const foundGroupMeeting = model.value.find(
      ({ id }) => id === groupMeetingId,
    );

    if (!foundGroupMeeting) {
      return new Error("Groepsbespreking kan niet gevonden worden");
    }

    const foundMentorClass = foundGroupMeeting.mentor_classes.find(
      ({ title }) => title === mentorClassTitle,
    );

    if (!foundMentorClass) {
      return new Error("Mentorklas kan niet gevonden worden");
    }

    return foundMentorClass;
  };

  /**
   * Updates the chats for a mentor class in a group meeting.
   * @param {Object} groupMeetings - The group meetings.
   * @param {number} groupMeetingId - The ID of the group meeting.
   * @param {string} mentorClass - The mentor class.
   * @param {Object} data - The chat data to update.
   * @returns {void|Error} Void or an error if the update fails.
   */
  const updateMentorClassChats = (
    groupMeetings,
    groupMeetingId,
    mentorClass,
    data,
  ) => {
    try {
      if (!groupMeetings.value.length) {
        return;
      }

      const foundMentorClass = findMentorClassInGroupMeeting(
        groupMeetings,
        groupMeetingId,
        mentorClass,
      );

      foundMentorClass.chats.push(data);
    } catch (error) {
      return new Error(error);
    }
  };

  /**
   * Adds a chat to a group meeting.
   * @param {number} groupMeetingId - The ID of the group meeting.
   * @param {Object} chatData - The chat data to add.
   * @returns {Promise<void>}
   */
  const addGroupMeetingChat = async (groupMeetingId, chatData) => {
    const { schoolId } = useHelpers();

    try {
      const { data } = await axios.post(
        `api/school/${schoolId}/group-meetings/${groupMeetingId}/chat`,
        chatData,
      );

      const overviewStore = useOverviewStore();
      const { group_meetings } = storeToRefs(overviewStore);

      updateMentorClassChats(
        myStudentsGroupMeetings,
        groupMeetingId,
        data.mentor_class,
        data,
      );
      updateMentorClassChats(
        myLessongroupsGroupMeetings,
        groupMeetingId,
        data.mentor_class,
        data,
      );
      updateMentorClassChats(
        group_meetings,
        groupMeetingId,
        data.mentor_class,
        data,
      );
    } catch (error) {
      console.log("🚀 ~ addGroupMeetingChat ~ error:", error);

      if (axios.isAxiosError(error)) {
        return Promise.reject(error);
      }
    }
  };

  /**
   * Adds an agreement to a group meeting.
   * @param {Object} params - The parameters.
   * @param {number} params.meetingId - The ID of the meeting.
   * @param {string} params.agreement - The agreement to add.
   * @param {string} params.mentorClass - The mentor class.
   * @returns {Promise<Object>} The added agreement.
   */
  const addGroupMeetingAgreement = async ({
    meetingId,
    agreement,
    mentorClass,
  } = {}) => {
    const { schoolId } = useHelpers();

    try {
      const { data } = await axios.post(
        `/api/school/${schoolId}/group-meetings/${meetingId}/agreements`,
        {
          mentor_class: mentorClass,
          type: "agreement",
          value: agreement,
        },
      );

      const meeting = myStudentsGroupMeetings.value.find(
        (groupMeeting) => groupMeeting.id === meetingId,
      );

      const mentorclass = meeting.mentor_classes.find(
        (mClass) => mClass.title === mentorClass,
      );

      mentorclass.agreements = data;

      return data;
    } catch (error) {
      $q.notify({
        color: "negative",
        message: `${
          error.response?.data?.message ??
          "Er is iets misgegaan bij het toevoegen van de afspraak"
        }`,
      });
      console.log("🚀 ~ addGroupMeetingAgreement ~ error:", error);
      if (axios.isAxiosError(error)) {
        return Promise.reject(error);
      }
    }
  };

  /**
   * Deletes an agreement from a group meeting.
   * @param {Object} params - The parameters.
   * @param {number} params.meetingId - The ID of the meeting.
   * @param {number} params.id - The ID of the agreement to delete.
   * @returns {Promise<void>}
   */
  const deleteGroupMeetingAgreement = async ({ meetingId, id } = {}) => {
    const { schoolId } = useHelpers();

    try {
      const { data } = await axios.delete(
        `/api/school/${schoolId}/group-meetings/${meetingId}/agreements/${id}`,
      );

      // Find the meeting and mentor class
      const meeting = myStudentsGroupMeetings.value.find(
        (groupMeeting) => groupMeeting.id === meetingId,
      );

      if (meeting) {
        meeting.mentor_classes.forEach((mentorClass) => {
          const agreementIndex = mentorClass.agreements.data.findIndex(
            (agreement) => agreement.id === id,
          );

          if (agreementIndex !== -1) {
            mentorClass.agreements = data;
          }
        });
      }

      $q.notify({
        color: "positive",
        message: `De afspraak is succesvol verwijderd`,
      });
    } catch (error) {
      $q.notify({
        color: "negative",
        message: `${
          error.response?.data?.message ??
          "Er is iets misgegaan bij het verwijderen van de afspraak"
        }`,
      });
      console.log("🚀 ~ deleteGroupMeetingAgreement ~ error:", error);
      if (axios.isAxiosError(error)) {
        return Promise.reject(error);
      }
    }
  };

  /**
   * Updates an agreement in a group meeting.
   * @param {Object} params - The parameters.
   * @param {number} params.meetingId - The ID of the meeting.
   * @param {number} params.id - The ID of the agreement to update.
   * @param {string} params.value - The new value of the agreement.
   * @returns {Promise<Object>} The updated agreement.
   */
  const updateGroupMeetingAgreement = async ({ meetingId, id, value } = {}) => {
    const { schoolId } = useHelpers();

    try {
      const { data } = await axios.put(
        `/api/school/${schoolId}/group-meetings/${meetingId}/agreements/${id}`,
        {
          value: value,
        },
      );

      // Find the meeting and mentor class
      const meeting = myStudentsGroupMeetings.value.find(
        (groupMeeting) => groupMeeting.id === meetingId,
      );

      if (meeting) {
        meeting.mentor_classes.forEach((mentorClass) => {
          const agreementIndex = mentorClass.agreements.data.findIndex(
            (agreement) => agreement.id === id,
          );

          if (agreementIndex !== -1) {
            mentorClass.agreements = data;
          }
        });
      }

      $q.notify({
        color: "positive",
        message: `De afspraak is succesvol aangepast`,
      });

      return data;
    } catch (error) {
      $q.notify({
        color: "negative",
        message: `${
          error.response?.data?.message ??
          "Er is iets misgegaan bij het aanpassen van het doel"
        }`,
      });
      console.log("🚀 ~ updateGroupMeetingAgreement ~ error:", error);
      if (axios.isAxiosError(error)) {
        return Promise.reject(error);
      }
    }
  };

  return {
    meeting,
    initialMeeting,
    teachers,
    meetings,
    myStudentsGroupMeetings,
    myLessongroupsGroupMeetings,
    fetchMeetings,
    fetchTeachers,
    initialStudents,
    initialSchedules,
    isAllowed,
    isOverview,
    isEditable,
    isExistingMeeting,
    groupMeetingConfig,
    storeStudentMeeting,
    storeTransitionMeeting,
    storeGroupMeeting,
    fetchTransitionMeeting,
    fetchGroupMeeting,
    removeMeeting,
    updatePlanning,
    updateMeeting,
    updateGroupMeetingSelectedTeachers,
    updateStudentsAndSubjects,
    clearShareFeedbackAt,
    updateGroupMeetingRating,
    updateGroupMeetingRatingTypes,
    fetchMyStudentsGroupMeetings,
    fetchMyLessongroupsGroupMeetings,
    addGroupMeetingGoal,
    addGroupMeetingAgreement,
    addGroupMeetingChat,
    deleteGroupMeetingGoal,
    updateGroupMeetingGoal,
    deleteGroupMeetingAgreement,
    updateGroupMeetingAgreement,
  };
});

if (import.meta.hot) {
  import.meta.hot.accept(acceptHMRUpdate(useMeetingStore, import.meta.hot));
}
